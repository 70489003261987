import React from 'react'
import SupabaseProvider from './src/providers/supabase-provider'
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

const onRedirectCallback = (appState) => {
 // Use Gatsby's navigate method to replace the url
 navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => {
 return (
    <Auth0Provider
    domain={'movigy.us.auth0.com'}
    clientId={'x4Ba7YyK18U9PN25KaOEKIEu8d5qLHUQ'}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    >
     <SupabaseProvider> {element}</SupabaseProvider>
    
  </Auth0Provider>
 );
};