import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import './fonts/fonts.scss'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#FF7A2F',/* Orange */
      light: '#DCDCDC', /* light Gray */
      dark: '#263e7d',
    },
    secondary: {
      main: '#2F69FF', /* Blue */
      light: 'rgba(47, 105, 255, 0.1)',/* light blue */
      orange: '#FF7A2F1A'
    },
    text: {
      primary: '#1f3748',
      light: '#FFFFFF',
      secondary:'#606060',/* Gray */
      title: '#747474',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    h3:{
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '24px',
    },
    h2:{
      fontSize: '26px',
      fontWeight:'450',
      lineHeight:'32px',
    },
    p:{
      fontWeight:'500',
      fontSize:'14px',
      lineHeight:'21px',
    }
  }
})

export default theme
