exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assessment-assessment-id-js": () => import("./../../../src/pages/assessment/[assessmentId].js" /* webpackChunkName: "component---src-pages-assessment-assessment-id-js" */),
  "component---src-pages-assessment-index-js": () => import("./../../../src/pages/assessment/index.js" /* webpackChunkName: "component---src-pages-assessment-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-resultado-index-js": () => import("./../../../src/pages/resultado/index.js" /* webpackChunkName: "component---src-pages-resultado-index-js" */),
  "component---src-pages-resultado-result-id-js": () => import("./../../../src/pages/resultado/[resultId].js" /* webpackChunkName: "component---src-pages-resultado-result-id-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

